import { TuiBadge, TuiStatus } from '@taiga-ui/kit';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

@Component({
  selector: 'chassis-free-shipping-badge',
  standalone: true,
  imports: [CommonModule, TuiBadge, TuiStatus],
  templateUrl: './shared-components-free-shipping-badge.component.html',
  styleUrls: ['./shared-components-free-shipping-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedComponentsFreeShippingBadgeComponent {
  private datePipeTf = new DatePipe('en-US');
  displayText = '';
  @Input()
  set date(date: string | undefined) {
    if (date && date !== 'No information') {
      this.displayText = `Est Delivery Date ${this.datePipeTf.transform(
        date,
        'M/d/yy',
      )}`;
    } else {
      const estDate = new Date();
      estDate.setDate(estDate.getDate() + 4);
      this.displayText = `Est Delivery Date ${this.datePipeTf.transform(
        estDate,
        'M/d/yy',
      )}`;
    }
  }
}
