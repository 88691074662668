<h2 data-testid="total-header">Order Summary</h2>

<ng-container
  *ngrxLet="{
    cartItems: cartItems$,
    total: total$,
  } as vm"
  data-testid="checkout-data"
>
  @for (cartItem of vm.cartItems; track trackCartItem(cartItem)) {
    <div class="products-quantity" data-testid="checkout-product">
      <p data-testid="checkout-product-name">
        {{ cartItem.product_name }}
        @if (cartItem.current_core_charge > 0.0) {
          <span class="fees-included" data-testid="checkout-core-included"
            >(Core Charge Included)</span
          >
        }
      </p>
      <div class="products-quantity-values">
        <p data-testid="checkout-product-price">
          {{ cartItem.revenue | displayPrice }} x
          {{ cartItem.quantity }}
        </p>
      </div>
    </div>
  }

  <div class="products-quantity">
    <p>Shipping</p>
    <p class="money">Free</p>
  </div>
  <div class="products-quantity">
    <p>Tax</p>
    <p class="money">$0.00</p>
  </div>
  <hr class="break" data-testid="checkout-break" />
  <div
    class="products-quantity-values-total"
    data-testid="checkout-product-quantity-values"
  >
    <h2 data-testid="checkout-order-total-header">Order Total</h2>
    <h2 class="total" data-testid="order-total">
      ${{ orderTotal(vm.total, shippingPrice) | number: '1.2-2' }}
    </h2>
  </div>
  <div *ngIf="checkout; else cart" data-testid="checkout-btn-container">
    <button
      tuiButton
      type="button"
      size="m"
      class="tui-space_right-3 tui-space_bottom-3 btn apf-button"
      (click)="submitBilling.emit()"
      data-testid="checkout-from-total-button"
    >
      Place Order
    </button>
  </div>
  <ng-template #cart data-testid="checkout-checkout-btn-container">
    <a
      [class]="!vm.cartItems?.length ? 'disabledTag' : ''"
      tuiButton
      size="m"
      type="button"
      [class.disabled]="!vm.cartItems?.length"
      [routerLink]="'/checkout'"
      class="tui-space_right-3 tui-space_bottom-3 btn"
      data-testid="checkout-checkout-btn"
    >
      Checkout
    </a>
  </ng-template>
</ng-container>
