import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import * as CartActions from './cart.actions';
import * as CartItemActions from './cart-item.actions';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { CartService } from '../services/cart.service';
import { SharedActions } from '@chassis/shared/actions';
import { NotificationsActions } from '@chassis/shared/notifications';
import { OrderType, OrderTypeLongForm } from '@chassis/shared/models';
import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  ConnectActions,
  DeliveryTicketLineItem,
  DeliveryTicketResponse,
  WorkOrderLineItem,
  WorkOrderResponse,
} from '@chassis/desktop/connect/frontend';
import { HttpStatusCode } from '@angular/common/http';
import { Cart, CartImport, CartImportError, CartItemImport } from '../models';
import { selectCart } from '../store/cart.reducer';
import { LogrocketService } from '@chassis/shared/services/logrocket';
import { OrderService } from '@chassis/shared/services/orders';
import { AllRoutes } from '@chassis/shared/routes';

@Injectable()
export class CartEffects {
  private store = inject(Store);
  private router = inject(Router);
  private actions$ = inject(Actions);
  private cartService = inject(CartService);
  private logrocketService = inject(LogrocketService);
  private ordersService = inject(OrderService);

  cartPostAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.initPostAuth),
      switchMap(() => [CartActions.get()]),
    ),
  );

  getCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.get),
      switchMap(() => {
        return this.cartService.load().pipe(
          switchMap((cart) => {
            const success = CartActions.getSuccess({ cart });

            if (!cart.external_id && !cart.metadata?.external_number) {
              return [success];
            }

            if (cart.metadata?.type === OrderType.WO) {
              return [
                success,
                ConnectActions.woFetch({
                  wo: String(cart.metadata?.external_number),
                }),
              ];
            }

            if (cart.metadata?.type === OrderType.DT) {
              return [
                success,
                ConnectActions.dtFetch({
                  dt: String(cart.metadata?.external_number),
                }),
              ];
            }

            return [success];
          }),
          catchError((error) => [
            CartActions.getFail({ error }),
            NotificationsActions.error({
              message: 'Error fetching cart',
              location: 'cart',
            }),
          ]),
        );
      }),
    ),
  );

  workOrderImport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.woFetchSuccess, ConnectActions.woRefreshSuccess),
      map((action) => this.buildWorkOrderCartImport(action.data)),
      switchMap((payload: CartImport) => {
        this.logrocketService.track(
          'workorder',
          payload.metadata.external_number,
        );
        return this.ordersService
          .getOrders({ external_id: payload.metadata.external_number })
          .pipe(
            map(({ meta }) => meta.total_count > 0),
            switchMap((previousOrder) => {
              if (previousOrder) {
                return of(
                  NotificationsActions.error({
                    message: `This order has already been placed. Please check the order history view.`,
                    location: 'cart',
                    showReload: false,
                  }),
                );
              } else {
                return this.cartService.import(payload).pipe(
                  map((cart) =>
                    CartActions.importSuccess({
                      cart,
                    }),
                  ),
                  catchError((errorResponse) =>
                    this.buildCartImportErrors(
                      OrderTypeLongForm.WO,
                      errorResponse,
                    ),
                  ),
                );
              }
            }),
          );
      }),
    );
  });

  deliveryTicketImport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.dtFetchSuccess, ConnectActions.dtRefreshSuccess),
      map((action) => this.buildDeliveryTicketCartImport(action.data)),
      switchMap((payload: CartImport) => {
        this.logrocketService.track(
          'deliveryTicket',
          payload.metadata.external_number,
        );

        return this.ordersService
          .getOrders({ external_id: payload.metadata.external_number })
          .pipe(
            map(({ meta }) => meta.total_count > 0),
            switchMap((previousOrder) => {
              if (previousOrder) {
                return of(
                  NotificationsActions.error({
                    message: `This order has already been placed. Please check the order history view.`,
                    location: 'cart',
                    showReload: false,
                  }),
                );
              } else {
                return this.cartService.import(payload).pipe(
                  map((cart) =>
                    CartActions.importSuccess({
                      cart,
                    }),
                  ),
                  catchError((errorResponse) =>
                    this.buildCartImportErrors(
                      OrderTypeLongForm.DT,
                      errorResponse,
                    ),
                  ),
                );
              }
            }),
          );
      }),
    );
  });

  orderImportSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.importSuccess),
        tap(() => this.router.navigate([AllRoutes.checkout])),
      ),
    { dispatch: false },
  );

  /**
   * Handle errors when importing a cart. Clears cart and metadata
   */
  orderImportFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.importFail),
      switchMap(() => [
        CartActions.clearCart({}),
        ConnectActions.clearOrderInfo(),
      ]),
    ),
  );

  updateCartRevisionPreCheckoutRefreshSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ConnectActions.preCheckoutDtRefreshSuccess,
        ConnectActions.preCheckoutWoRefreshSuccess,
      ),
      concatLatestFrom(() => this.store.select(selectCart)),
      switchMap(([{ revision, createAction }, cart]) => {
        return this.cartService
          .update({
            metadata: {
              ...cart.metadata,
              revision,
            },
          })
          .pipe(map(() => createAction));
      }),
    ),
  );

  clearCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.clearCart),
      switchMap(({ item }) =>
        this.cartService.clear().pipe(
          mergeMap((cart) => {
            const dispatch: any[] = [CartActions.clearCartSuccess({ cart })];
            if (item) {
              dispatch.push(CartItemActions.add({ item, redirect: true }));
            }
            return dispatch;
          }),
          catchError((error) => [
            CartActions.clearCartFail({ error }),
            NotificationsActions.error({
              message: 'Error clearing cart',
            }),
          ]),
        ),
      ),
    ),
  );

  cartUpdate = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.cartUpdate),
      switchMap(({ cart }: { cart: Partial<Cart> }) =>
        this.cartService.update(cart).pipe(
          map((cart) => CartActions.cartUpdateSuccess({ cart })),
          catchError((error) => of(CartActions.cartUpdateFail({ error }))),
        ),
      ),
    ),
  );

  private buildCartImportErrors(
    orderType: OrderTypeLongForm,
    errorResponse: any,
  ): Action[] {
    const errorNotifications = errorResponse.error.errors.map(
      ({ message, cart_item }: CartImportError) => {
        if (errorResponse.status === HttpStatusCode.NotFound && cart_item) {
          return NotificationsActions.error({
            message: `This ${orderType} contains a product that no longer exists (InventoryID ${cart_item.external_id}). Please remove it from the work order in Powerlink and re-submit.`,
            location: 'cart',
            showReload: false,
          });
        }

        return NotificationsActions.error({
          message: message,
          location: 'cart',
          showReload: false,
        });
      },
    );
    return [
      ...errorNotifications,
      CartActions.importFail({ error: errorResponse }),
    ];
  }

  private buildWorkOrderCartImport(data: WorkOrderResponse): CartImport {
    const { workOrder, lineItems } = data;
    const type = OrderType.WO;
    return {
      external_id: workOrder.workOrderID,
      metadata: {
        external_number: workOrder.workOrderNumber,
        type,
        revision: workOrder.revision,
        ebay_order_id: workOrder.ebayOrderNumber,
      },
      cart_items: lineItems.map((item: WorkOrderLineItem): CartItemImport => {
        return {
          external_inventory_id: item.localInventoryID,
          external_id: item.lineItemID,
          metadata: {
            revision: item.revision,
            type,
            parent_id: item.workOrderID,
            ebay_item_id: item.eBayItemID,
          },
          quantity: item.quantity,
          revenue: item.unitPrice,
          original_core_charge: item.corePrice,
        };
      }),
    };
  }

  private buildDeliveryTicketCartImport(
    data: DeliveryTicketResponse,
  ): CartImport {
    const { deliveryTicket, lineItems } = data;
    const type = OrderType.DT;

    return {
      external_id: deliveryTicket.deliveryTicketID,
      metadata: {
        external_number: deliveryTicket.deliveryTicketNumber,
        type,
        revision: deliveryTicket.revision,
        ebay_order_id: deliveryTicket.ebayOrderNumber,
      },
      cart_items: lineItems.map((item: DeliveryTicketLineItem) => {
        return {
          external_inventory_id: item.inventoryID,
          external_id: item.lineItemID,
          metadata: {
            revision: item.revision,
            type,
            parent_id: deliveryTicket.deliveryTicketID,
            ebay_item_id: item.eBayItemID,
          },
          quantity: item.quantity,
          revenue: item.unitPrice,
          original_core_charge: 0,
        };
      }),
    };
  }
}
