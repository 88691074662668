import { tuiInputNumberOptionsProvider } from '@taiga-ui/legacy';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  input,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';
import { CartItem } from '../../models/index';
import { TUI_NUMBER_FORMAT, tuiNumberFormatProvider } from '@taiga-ui/core';

@Component({
  selector: 'cart-shared-product-quantity',
  templateUrl: './product-quantity.component.html',
  styleUrls: ['./product-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductQuantityComponent),
      multi: true,
    },
    tuiNumberFormatProvider({
      precision: 0,
    }),
    tuiInputNumberOptionsProvider({
      step: 1,
    }),
  ],
})
export class ProductQuantityComponent implements ControlValueAccessor {
  control = new FormControl(0);

  public productQuantity = 1;
  public disabled = false;
  public touched = false;
  product = input.required<CartItem>();

  @Output() delete = new EventEmitter<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (quantity: number) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = (v) => {
      this.productQuantity = v;
      fn(v);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(quantity: any): void {
    this.productQuantity = quantity;
  }

  @tuiPure
  itemTotal(display_price: any, core_charge: any) {
    return (Number(core_charge) || 0) + (Number(display_price) || 0);
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  @Input() hidePrice = false;
}
