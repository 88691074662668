import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { tuiPure } from '@taiga-ui/cdk';
import { selectCartItems } from '../../store/cart.reducer';
import { CartItem } from '../../models';
import { filter, map, Observable } from 'rxjs';

@Component({
  selector: 'cart-shared-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalComponent {
  public cartItems$: Observable<CartItem[]> =
    this.store.select(selectCartItems);

  public total$ = this.cartItems$.pipe(
    filter((cartItems) => !!cartItems?.length),
    map((cartItems) => this.calculateOrderTotal(cartItems)),
  );
  constructor(private store: Store) {}

  @Input() checkout = false;

  @Input() shippingPrice = 0;

  @Output() submitBilling = new EventEmitter();

  @tuiPure
  toNumber(str: string): number {
    return Number(str);
  }

  @tuiPure
  orderTotal(orderValue: number, shipping: number) {
    return orderValue + shipping;
  }

  @tuiPure
  itemTotal(cartItem: CartItem) {
    return Number(cartItem.current_core_charge || 0) + Number(cartItem.revenue);
  }

  calculateOrderTotal(cartItems: CartItem[]) {
    return cartItems.reduce((total, cartItem) => {
      return total + this.itemTotal(cartItem) * cartItem.quantity;
    }, 0);
  }

  trackCartItem(cartItem: CartItem) {
    return cartItem.id;
  }
}
