import { createReducer, createSelector, on } from '@ngrx/store';
import * as CartActions from './cart.actions';
import * as CartItemActions from './cart-item.actions';
import { Cart, CartItem } from '../models';
import { Entity } from '@chassis/shared/models';

export enum CartType {
  Standard = 'standard',
  Imported = 'imported',
}

export interface CartState {
  cartType: CartType | null;
  cart: Cart;
  cartItems: Entity<CartItem>;
  totalItems: number;
}

const initialCart: Cart = {
  id: 0,
  external_id: 0,
  metadata: null,
  created_at: '',
  updated_at: '',
  submitted_at: null,
};

export const initialCartState: CartState = {
  cartType: null,
  cart: initialCart,
  cartItems: {},
  totalItems: 0,
};

interface ReducerResponse {
  cartItems: Entity<CartItem>;
  totalItems: number;
}

const reduceCartItems = (
  state: CartState,
  cartItemList: CartItem[] | undefined,
): ReducerResponse => {
  if (!cartItemList?.length) {
    return { cartItems: state.cartItems, totalItems: state.totalItems };
  }

  return cartItemList.reduce(
    (e: ReducerResponse, cartItem: CartItem): ReducerResponse => {
      const existingItemQuantity =
        e.cartItems[cartItem.variant_id]?.quantity || 0;
      const cartItems = { ...e.cartItems, [cartItem.variant_id]: cartItem };
      const totalItems =
        e.totalItems + cartItem.quantity - existingItemQuantity;
      return { cartItems, totalItems };
    },
    { cartItems: state.cartItems, totalItems: state.totalItems },
  );
};

export const cartReducer = createReducer(
  initialCartState,
  on(CartActions.getSuccess, (state, { cart }): CartState => {
    const { cart_items, ...rest } = cart;
    const { cartItems, totalItems } = reduceCartItems(
      initialCartState,
      cart_items,
    );
    return { cartType: CartType.Standard, cart: rest, cartItems, totalItems };
  }),
  on(CartActions.importSuccess, (state, { cart }): CartState => {
    const { cart_items, ...rest } = cart;
    const { cartItems, totalItems } = reduceCartItems(
      initialCartState,
      cart_items,
    );
    return { cartType: CartType.Imported, cart: rest, cartItems, totalItems };
  }),
  on(CartActions.cartUpdateSuccess, (state, { cart }): CartState => {
    const { cart_items, ...rest } = cart;
    const { cartItems, totalItems } = reduceCartItems(
      initialCartState,
      cart_items,
    );
    return { cartType: CartType.Imported, cart: rest, cartItems, totalItems };
  }),
  on(CartActions.clearCartSuccess, (state, { cart }): CartState => {
    const { cart_items, ...rest } = cart;
    const { cartItems, totalItems } = reduceCartItems(
      initialCartState,
      cart_items,
    );
    return { cartType: null, cart: rest, cartItems, totalItems };
  }),

  // CartItems
  on(CartItemActions.addSuccess, (state, { cartItem }): CartState => {
    const { cartItems, totalItems } = reduceCartItems(state, [cartItem]);
    return { ...state, cartItems, totalItems };
  }),
  on(CartItemActions.updateSuccess, (state, { cartItem }): CartState => {
    const { cartItems, totalItems } = reduceCartItems(state, [cartItem]);
    return { ...state, cartItems, totalItems };
  }),
  on(CartItemActions.destroySuccess, (state, { cartItem }): CartState => {
    const { [cartItem.variant_id]: deleted, ...cartItems } = state.cartItems;
    const totalItems = state.totalItems - cartItem.quantity;
    return { ...state, cartItems, totalItems };
  }),
);

export const cartState = (state: any): CartState => state.cart;

export const selectCartState = createSelector(cartState, (state) => {
  return state;
});

export const selectCartType = createSelector(
  cartState,
  (state) => state?.cartType,
);

export const selectCart = createSelector(cartState, (state) => state?.cart);

export const selectCartCount = createSelector(
  cartState,
  (state) => state?.totalItems,
);

export const selectCartMetaData = createSelector(
  selectCart,
  (cart) => cart?.metadata,
);

export const selectCartItemEntities = createSelector(
  cartState,
  (state) => state?.cartItems,
);

export const selectCartItems = createSelector(
  selectCartItemEntities,
  (cartItems): CartItem[] => (cartItems ? Object.values(cartItems) : []),
);

export const selectCartItem = (variant_id: number) =>
  createSelector(selectCartItemEntities, (cartItems) => {
    return Object.values(cartItems)?.filter(
      (cartItem) => cartItem.variant_id === variant_id,
    )[0];
  });
