import { createAction, props } from '@ngrx/store';
import { AddCartItem, Cart } from '../models';

export enum CartActionsTypes {
  Get = '[CART] GET',
  GetSuccess = '[CART] GET SUCCESS',
  GetFail = '[CART] GET FAIL',

  ClearCart = '[CART] CLEAR CART',
  ClearCartSuccess = '[CART] CLEAR CART SUCCESS',
  ClearCartFail = '[CART] CLEAR CART FAIL',

  ImportSuccess = '[CART] IMPORT SUCCESS',
  ImportFail = '[CART] IMPORT FAIL',

  CartUpdate = '[CART] UPDATE',
  CartUpdateSuccess = '[CART] UPDATE SUCCESS',
  CartUpdateFail = '[CART] UPDATE FAIL',
}

export const get = createAction(CartActionsTypes.Get);

export const getSuccess = createAction(
  CartActionsTypes.GetSuccess,
  props<{ cart: Cart }>(),
);

export const getFail = createAction(
  CartActionsTypes.GetFail,
  props<{ error: any }>(),
);

export const importSuccess = createAction(
  CartActionsTypes.ImportSuccess,
  props<{ cart: Cart }>(),
);

export const importFail = createAction(
  CartActionsTypes.ImportFail,
  props<{ error: any }>(),
);

export const clearCart = createAction(
  CartActionsTypes.ClearCart,
  props<{ item?: AddCartItem }>(),
);
export const clearCartSuccess = createAction(
  CartActionsTypes.ClearCartSuccess,
  props<{ cart: Cart }>(),
);
export const clearCartFail = createAction(
  CartActionsTypes.ClearCartFail,
  props<{ error: any }>(),
);

export const cartUpdate = createAction(
  CartActionsTypes.CartUpdate,
  props<{ cart: Partial<Cart> }>(),
);
export const cartUpdateSuccess = createAction(
  CartActionsTypes.ClearCartSuccess,
  props<{ cart: Cart }>(),
);
export const cartUpdateFail = createAction(
  CartActionsTypes.CartUpdateFail,
  props<{ error: any }>(),
);
