import { createAction, props } from '@ngrx/store';
import { AddCartItem, CartItem } from '../models';

export enum CartItemActionsTypes {
  Get = '[CART ITEM] GET',
  GetSuccess = '[CART ITEM] GET SUCCESS',
  GetFail = '[CART ITEM] GET FAIL',

  Add = '[CART ITEM] ADD',
  AddSuccess = '[CART ITEM] ADD SUCCESS',
  AddFail = '[CART ITEM] ADD FAIL',

  Update = '[CART ITEM] UPDATE',
  UpdateSuccess = '[CART ITEM] UPDATE SUCCESS',
  UpdateFail = '[CART ITEM] UPDATE FAIL',

  Destroy = '[CART ITEM] DESTROY',
  DestroySuccess = '[CART ITEM] DESTROY SUCCESS',
  DestroyFail = '[CART ITEM] DESTROY FAIL',
}

export const get = createAction(CartItemActionsTypes.Get);

export const getSuccess = createAction(
  CartItemActionsTypes.GetSuccess,
  props<{ cartItem: CartItem }>(),
);

export const getFail = createAction(
  CartItemActionsTypes.GetFail,
  props<{ error: any }>(),
);

export const add = createAction(
  CartItemActionsTypes.Add,
  props<{ item: AddCartItem; redirect?: boolean }>(),
);

export const addSuccess = createAction(
  CartItemActionsTypes.AddSuccess,
  props<{ cartItem: CartItem; redirect?: boolean }>(),
);

export const addFail = createAction(
  CartItemActionsTypes.AddFail,
  props<{ error: any }>(),
);

export const update = createAction(
  CartItemActionsTypes.Update,
  props<{ cartItem: CartItem; redirect?: boolean }>(),
);

export const updateSuccess = createAction(
  CartItemActionsTypes.UpdateSuccess,
  props<{ cartItem: CartItem; redirect?: boolean }>(),
);

export const updateFail = createAction(
  CartItemActionsTypes.UpdateFail,
  props<{ error: any }>(),
);

export const destroy = createAction(
  CartItemActionsTypes.Destroy,
  props<{ cartItem: CartItem }>(),
);

export const destroySuccess = createAction(
  CartItemActionsTypes.DestroySuccess,
  props<{ cartItem: CartItem }>(),
);

export const destroyFail = createAction(
  CartItemActionsTypes.DestroyFail,
  props<{ error: any }>(),
);
