<div
  tuiDropdownAlign="right"
  [tuiDropdown]="dropdown"
  [(tuiDropdownOpen)]="open"
>
  <div class="pricing-adjustment">
    <ng-content></ng-content>
    <tui-icon
      [icon]="open ? '@tui.chevron-left' : '@tui.chevron-down'"
      class="arrow"
      [class.arrow_open]="open"
    ></tui-icon>
  </div>
</div>
<ng-template #dropdown>
  <tui-data-list>
    @for (price of prices(); track trackPrice(price)) {
      <button class="listing-price" (click)="setPrice(price.value)" tuiOption>
        <span class="label">{{ price.label }}</span>
        <span>{{ price.value }}</span>
      </button>
    }
  </tui-data-list>
</ng-template>
