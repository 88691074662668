import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import * as CartActions from './cart.actions';
import * as CartItemActions from './cart-item.actions';
import { catchError, map, mergeMap, tap } from 'rxjs';
import { CartItemService } from '../services/cart-item.service';
import { NotificationsActions } from '@chassis/shared/notifications';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  CartType,
  selectCartItem,
  selectCartType,
} from '../store/cart.reducer';
import { AllRoutes } from '@chassis/shared/routes';

@Injectable()
export class CartItemEffects {
  private store = inject(Store);
  private router = inject(Router);
  private actions$ = inject(Actions);
  private cartItemService = inject(CartItemService);

  addCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartItemActions.add),
      concatLatestFrom(({ item }) => [
        this.store.select(selectCartType),
        this.store.select(selectCartItem(item.variant_id)),
      ]),
      mergeMap(([{ item, redirect }, cartType, existingCartItem]) => {
        if (cartType === CartType.Imported) {
          return [CartActions.clearCart({ item })];
        }

        if (existingCartItem) {
          return [
            CartItemActions.update({
              cartItem: {
                ...existingCartItem,
                quantity: existingCartItem.quantity + item.quantity,
                revenue: item.revenue,
              },
              redirect,
            }),
          ];
        }

        return this.cartItemService.create(item).pipe(
          map((cartItem) => CartItemActions.addSuccess({ cartItem, redirect })),
          catchError((error) => [
            CartItemActions.addFail({ error }),
            NotificationsActions.error({
              message: 'Error adding item to cart',
              location: 'cart',
            }),
          ]),
        );
      }),
    ),
  );

  updateCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartItemActions.update),
      mergeMap(({ cartItem, redirect }) =>
        this.cartItemService.update(cartItem).pipe(
          map((cartItem) =>
            CartItemActions.updateSuccess({ cartItem, redirect }),
          ),
          catchError((error) => [
            CartItemActions.updateFail({ error }),
            NotificationsActions.error({
              message: 'Error updating item in cart',
              location: 'cart',
            }),
          ]),
        ),
      ),
    ),
  );

  destroyCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartItemActions.destroy),
      mergeMap(({ cartItem }) =>
        this.cartItemService.destroy(cartItem.id).pipe(
          map(() => CartItemActions.destroySuccess({ cartItem })),
          catchError((error) => [
            CartItemActions.destroyFail({ error }),
            NotificationsActions.error({
              message: 'Error deleting item from cart',
              location: 'cart',
            }),
          ]),
        ),
      ),
    ),
  );

  successNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartItemActions.addSuccess, CartItemActions.updateSuccess),
        tap(({ redirect }) => {
          if (redirect) {
            this.router.navigate([AllRoutes.checkout]);
          }
        }),
      ),
    { dispatch: false },
  );
}
