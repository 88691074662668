import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  signal,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@chassis/shared/pipes';
import { TuiDataList, TuiDropdown, TuiIcon, TuiButton } from '@taiga-ui/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT } from '@chassis/shared/environment';
import { map } from 'rxjs';

export interface PricingList {
  [key: string]: number;
}

@Component({
  selector: 'apf-pricing-list',
  standalone: true,
  imports: [
    CommonModule,
    PipesModule,
    TuiDataList,
    TuiDropdown,
    TuiIcon,
    TuiButton,
  ],
  templateUrl: './pricing-list.component.html',
  styleUrl: './pricing-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingListComponent {
  readonly env = inject(ENVIRONMENT);

  public open = false;
  id = input.required<number>();

  prices = signal<{ label: string; value: number }[]>([]);

  @Output() outputPrice = new EventEmitter<number>();

  constructor(private httpClient: HttpClient) {
    effect(() => {
      this.httpClient
        .get<{ data: PricingList }>(`${this.env.BASEURL_V4}/prices`, {
          params: {
            product_id: this.id(),
          },
        })
        .pipe(
          map(({ data }) => {
            const output: { label: string; value: number }[] = [];
            for (const key in data) {
              const label = key.split('Price');

              output.push({ label: `${label[0]} Price`, value: data[key] });
            }
            return output;
          }),
        )
        .subscribe((data) => {
          this.prices.set(data);
        });
    });
  }

  trackPrice({ label }: { label: string; value: number }): string {
    return label;
  }

  setPrice(price: number) {
    this.outputPrice.emit(price);

    this.open = false;
  }
}
