import {
  TuiIslandDirective,
  TuiTextfieldControllerModule,
  TuiInputModule,
  TuiInputNumberModule,
} from '@taiga-ui/legacy';
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { cartReducer } from './store/cart.reducer';
import { TotalComponent } from './components/total/total.component';
import { PipesModule } from '@chassis/shared/pipes';
import { LetDirective } from '@ngrx/component';
import { TuiError, TuiIcon, TuiButton, TuiLabel } from '@taiga-ui/core';
import { RouterLink } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { CartEffects } from './store/cart.effects';
import { CartService } from './services/cart.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductQuantityComponent } from './components/product-quantity/product-quantity.component';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { SharedComponentsFreeShippingBadgeComponent } from '@chassis/shared/components/free-shipping-badge';
import { CartItemService } from './services/cart-item.service';
import { CartItemEffects } from './store/cart-item.effects';
import { PricingListComponent } from '@chassis/pricing-list';
import { SummaryComponent } from './components/summary/summary.component';
import { DefaultImgDirective } from '@chassis/shared/directives';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('cart', cartReducer),
    EffectsModule.forFeature([CartEffects, CartItemEffects]),
    PipesModule,
    LetDirective,
    TuiButton,
    RouterLink,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiLabel,
    FormsModule,
    TuiInputNumberModule,
    TuiError,
    TuiIslandDirective,
    SharedComponentsFreeShippingBadgeComponent,
    TuiIcon,
    TuiInputModule,
    PricingListComponent,
    NgOptimizedImage,
    DefaultImgDirective,
  ],
  declarations: [
    TotalComponent,
    ProductQuantityComponent,
    CartItemComponent,
    SummaryComponent,
  ],
  exports: [
    TotalComponent,
    CartItemComponent,
    ProductQuantityComponent,
    SummaryComponent,
  ],
  providers: [CartService, CartItemService],
})
export class CartSharedModule {}
