import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT, SharedEnvironment } from '@chassis/shared/environment';
import { HttpClient } from '@angular/common/http';
import { AddCartItem, CartItem } from '../models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartItemService {
  private env: SharedEnvironment = inject(ENVIRONMENT);
  private httpClient = inject(HttpClient);

  list(): Observable<CartItem[]> {
    return this.httpClient
      .get<{ data: CartItem[] }>(`${this.env.BASEURL_V4}/cart_items`)
      .pipe(map(({ data }) => data));
  }

  create(cartItem: AddCartItem): Observable<CartItem> {
    return this.httpClient
      .post<{ data: CartItem }>(`${this.env.BASEURL_V4}/cart_items`, {
        cart_item: cartItem,
      })
      .pipe(map(({ data }) => data));
  }

  update(cartItem: Partial<CartItem>): Observable<CartItem> {
    return this.httpClient
      .patch<{ data: CartItem }>(
        `${this.env.BASEURL_V4}/cart_items/${cartItem.id}`,
        {
          cart_item: cartItem,
        },
      )
      .pipe(map(({ data }) => data));
  }

  destroy(id: number): Observable<{ id: number }> {
    return this.httpClient
      .delete<{
        data: { id: number };
      }>(`${this.env.BASEURL_V4}/cart_items/${id}`)
      .pipe(map(({ data }) => data));
  }
}
