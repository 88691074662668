@if (cartItem) {
  <div class="cart-item-container" data-testid="cart-item">
    <tui-island data-testid="cart-item-island">
      <img
        [src]="cartItem.product_image_url"
        default="/assets/test.jpg"
        data-testid="cart-item-image"
        alt="product image"
      />
      <div class="cart-content" data-testid="cart-item-content">
        <h3 class="tui-island__title" data-testid="cart-item-title">
          {{ cartItem.product_name }}
        </h3>

        <div class="hollander-shipping">
          <div>
            <p>
              Price:
              <span class="product-price">{{
                cartItem.cost_price | displayPrice
              }}</span
              >/ea
            </p>
            @if (firstOrderFlow) {
              <div class="revenue">
                <span>Revenue: </span>
                <span class="primary">$</span>

                @if (editRevenue) {
                  <tui-input
                    tuiTextfieldSize="s"
                    [formControl]="revenueControl"
                  >
                    <input tuiTextfieldLegacy type="text" />
                  </tui-input>
                  <span>/ea</span>
                  <button
                    (click)="updateRevenue()"
                    size="xs"
                    tuiIconButton
                    type="button"
                    class="icon-button"
                    iconStart="@tui.check"
                  ></button>
                } @else {
                  <p class="revenue-value">
                    {{ revenueControl.getRawValue() }}
                  </p>
                  <span>/ea</span>
                  <button
                    (click)="editRevenue = true"
                    size="xs"
                    tuiIconButton
                    type="button"
                    class="icon-button"
                    iconStart="@tui.pencil"
                  ></button>
                }
                @if (cartItem.variant_id) {
                  <apf-pricing-list
                    [id]="cartItem.variant_id"
                    (outputPrice)="updateRevenueControlDispatchUpdate($event)"
                  >
                  </apf-pricing-list>
                }
              </div>
            }
            <p class="font-2" data-testid="cart-item-hollander-number">
              {{ cartItem.hollander_number || cartItem.sku }}
            </p>
            @if (cartItem.current_core_charge > 0.0) {
              <div class="core-charge" data-testid="cart-item-core-applies">
                <tui-icon icon="@tui.circle-alert" />Core Charge Applies ~
                <span class="price">{{
                  cartItem.current_core_charge | displayPrice
                }}</span
                >/ea
              </div>
            }
            <chassis-free-shipping-badge
              class="tui-space_right-2 free-shipping"
              [date]="cartItem.delivery_window"
            ></chassis-free-shipping-badge>
          </div>
          <cart-shared-product-quantity
            [hidePrice]="true"
            [product]="cartItem"
            [formControl]="quantityControl"
            (delete)="prompt()"
            data-testid="cart-item-quantity"
          ></cart-shared-product-quantity>
        </div>
      </div>
    </tui-island>
  </div>
}
