import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT, SharedEnvironment } from '@chassis/shared/environment';
import { HttpClient } from '@angular/common/http';
import { Cart, CartImport } from '../models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private env: SharedEnvironment = inject(ENVIRONMENT);
  private httpClient = inject(HttpClient);

  load(): Observable<Cart> {
    return this.httpClient
      .get<{ data: Cart }>(`${this.env.BASEURL_V4}/carts`)
      .pipe(map(({ data }) => data));
  }

  import(cart: CartImport) {
    return this.httpClient
      .post<{ data: Cart }>(`${this.env.BASEURL_V4}/carts/import`, {
        cart: cart,
      })
      .pipe(map(({ data }) => data));
  }

  update(cart: Partial<Cart>): Observable<Cart> {
    return this.httpClient
      .patch<{ data: Cart }>(`${this.env.BASEURL_V4}/carts`, {
        cart: cart,
      })
      .pipe(map(({ data }) => data));
  }

  clear() {
    return this.httpClient
      .post<{ data: Cart }>(`${this.env.BASEURL_V4}/carts/clear`, {})
      .pipe(map(({ data }) => data));
  }
}
